import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";
// import { FiArrowRightCircle } from "react-icons/fi";
// import { Link } from "gatsby";
// import Image from "@components/common/CloudinaryImage";
// import Title from "@components/common/Title";
// import Text from "@components/common/Text";
// import { InlineWidget } from "react-calendly";
// import { FaParking } from "react-icons/fa";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-5">
                <p>Per aspirants a policies locals, Guàrdia Urbana i Mossos d'Esquadra.</p>

                <p>
                    Et presentem l'únic i millor curs per preparar tant els psicotècnics
                    psicoprofessionals com els aptitudinals.
                </p>

                <p>
                    Un curs que engloba tots els psicotècnics de les oposicions a policia de
                    Catalunya.
                </p>

                <p>
                    Des de iOpos Acadèmia som especialistes en aquest tipus de preparacions i
                    gràcies a l'equip de psicòlegs que treballen a iOpos Acadèmia podem confeccionar
                    material de qualitat per a vosaltres.
                </p>

                <p>
                    Tots els vídeos que trobaràs al campus són supervisats en detall i testejats per
                    docents especialistes.
                </p>

                <p>
                    <strong>Què trobareu al curs?</strong>
                </p>

                <p>
                    <strong>Assignatura 1:</strong> Dos vídeos explicatius del test competencial amb
                    explicacions de les escales, preguntes i consells per superar amb èxit el test.
                    50 minuts de duració.
                </p>

                <p>
                    <strong>Assignatura 2:</strong> Dos vídeos explicatius del test de personalitat
                    amb els 16 rangs, escales, preguntes i consells per superar amb èxit el test. 50
                    minuts de duració.
                </p>

                <p>
                    <strong>Assignatura 3:</strong> Un vídeo explicatiu d'avaluació dels trets
                    clínics potencialment pertorbadors de l'acompliment en el treball. 11 escales
                    clíniques i 2 escales de control. 50 minuts de duració.
                </p>

                <p>
                    <strong>Assignatura 4:</strong> Dos vídeos explicatius del test Kostik Papi. El
                    PAPI és una eina d'anàlisi que permet identificar fins a 22 trets de
                    personalitat, centrats en 7 dimensions clau: Influència i ambició. 50 minuts de
                    duració.
                </p>

                <p>
                    <strong>Assignatura 5:</strong> Simulacre òmnibus dels test de personalitat,
                    clínic i competencial. Un cop ja teniu clars els conceptes dels test, és hora de
                    posar-vos a prova. Un vídeo orientatiu dels test de 46 minuts de duració, una
                    fitxa de les dimensions i escales, i un simulacre amb les respostes.
                </p>

                <p>
                    <strong>Assignatura 6, 7 i 8:</strong> Vídeos explicatius dels test aptitudinals
                    oficials de les oposicions amb material de suport. 3 hores de duració. Trucs i
                    tips per entendre els psicotècnics d'una forma més eficient i ràpida. Material
                    didàctic.
                </p>

                <p>
                    I per finalitzar, un vídeo explicatiu analitzant el perfil idoni policial. 14
                    minuts de duració.
                </p>

                <p>L'accés al curs són 30 dies des del moment que et matricules.</p>

                <p>És un curs sense tutor amb un format d'autoaprenentatge.</p>

                <p>
                    Si en finalitzar el curs necessites una formació més personalitzada, contacta
                    amb nosaltres.
                </p>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Tens alguna oposició en breu? Encara no t'ha preparat mai els psicotècnics? Tens un NO apte a l'entrevista? A iOpos Acadèmia tenim la solució."
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};
export default Product;
